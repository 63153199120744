import React from "react";
import { Link } from "react-router-dom";
import banner from '../images/banner.png';
import mob from '../images/phone007.png'
import "../css/banner.css";

function Banner() {
  return (
    <>
      <div className="banner-wrapper">
        <div className="banner-text">
          <p id="heading">All-in-one Super App for Shopping and Cashbacks
          </p>
          <p id="sub-heading">
          Get Cashbacks for Shopping from your favourite Brands and stores in single app
          </p>
          <Link to="https://play.google.com/store/search?q=hutti&c=apps&hl=en-IN">
            <button>Download Now</button>
          </Link>
        </div>
        <div className="banner-img">
          <img src={banner} alt="Shopper with shopping bags" id="lady-img" />
        </div>
      </div>
      <div className="banner-mobile">
        <div className="banner-heading">
          <p>All-in-one Super App </p>
          <p>for Shopping & Cashback</p>
          <span id="sub-heading">Get Cashbacks for Shopping from your <br/> favourite Brands and stores in single app</span>
          
        </div>
        <div className="banner-button">
          <Link to='https://play.google.com/store/search?q=hutti&c=apps&hl=en-IN'>
          <button>Get the App</button>
          </Link>
        </div>
        <div className="banner-mobile-image">
          <img
            src={mob}
            alt=""
          />
        </div>
      </div>
    </>
  );
}

export default Banner;
